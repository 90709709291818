import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { postIdentity } from 'redux/data/thunk';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ConsentTypeEnum, identityDocumentEnum } from 'types/enums';
import Form, { FormInstance } from 'rc-field-form';
import LoadingView from 'views/Loading';
import FieldWrapper from 'components/FieldWrapper';
import Button from 'components/Button';
import Radio from 'components/Radio';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { onlyDigits } from 'helpers/formatters';
import {
  validateID,
  validatePesel,
  validateCountry,
  validateCitizenship,
  validatePoliticalStatus,
} from 'helpers/validators';
import { countries } from 'helpers/countries';
import { routes } from 'routes';
import { useKeyboardCloseOnUnmount } from 'hooks/useKeyboardCloseOnUnmount';
import useConsents from 'hooks/useConsents';
import 'views/Identity/styles.scss';
import FloatingLabelTextInput from 'components/Inputs/FloatingLabelInputs/FloatingLabelTextInput/FloatingLabelTextInput';
import { FloatingLabelSelectInput } from 'components/Inputs/FloatingLabelInputs/FloatingLabelSelectInput/FloatingLabelSelectInput';

type IdentityFormData = {
  pesel: string;
  birthCountry: any;
  citizenship: any;
  idCard: string;
  politicalStatus: number;
};

const classNamePrefix: 'identity' = 'identity';

const IdentityView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useKeyboardCloseOnUnmount();
  const { pepConsents, pepConsentsValues } = useConsents();
  const questionnaire = useAppSelector(
    (state) => state.Data.appProgressTemp?.registrationSteps?.questionnaire
  );
  const [form]: [FormInstance<IdentityFormData>] = Form.useForm();

  const { isLoading, identity } = useAppSelector((state) => state.Data);
  const { userData, isLoading: userLoading } = useAppSelector(
    (state) => state.Account
  );

  const onSubmit = ({ idCard, ...data }: IdentityFormData) => {
    const selectedConsent = pepConsents.find(
      (consent) => consent.id === +data.politicalStatus
    );
    const isPep =
      selectedConsent && selectedConsent.type === ConsentTypeEnum.pep;

    const sendData = {
      ...data,
      birthCountry: data.birthCountry.value,
      citizenship: data.citizenship.value,
      identityDocuments: [
        { type: identityDocumentEnum.idCard, number: idCard.toUpperCase() },
      ],
      consents: pepConsents.map(({ id }) => ({
        id,
        checked: id === selectedConsent?.id,
      })),
    };
    form.resetFields();
    dispatch(postIdentity(sendData)).then((action) => {
      if (action.payload?.status === 200) {
        if (isPep) {
          navigate(routes.contact);
        } else if (questionnaire) {
          navigate(routes.consents);
        } else {
          navigate(routes.limit);
        }
      }
    });
  };

  React.useEffect(() => {
    if (form && (identity || userData)) {
      const defaultData = identity ?? {
        pesel: userData?.client?.pesel ?? undefined,
        birthCountry:
          countries.find(
            (country) => country.code === userData?.client?.birthCountry
          )?.code ?? 'PL',
        citizenship:
          countries.find(
            (country) => country.code === userData?.client?.citizenship
          )?.code ?? 'PL',
        idCard: userData?.client?.idCard,
        politicalStatus: pepConsentsValues.find(({ checked }) => !!checked)?.id,
      };

      form.setFieldsValue(defaultData);
    }
  }, [form, identity, userData, pepConsentsValues]);

  const ConutriesSelectOptions = countries.map((country) => {
    return {
      value: country.code,
      label: country.name_pl,
    };
  });

  if (userLoading) {
    return <LoadingView text="Trwa wczytywanie danych" />;
  }

  return (
    <div className={classNamePrefix} ref={ref}>
      <div className={`${classNamePrefix}__inner`}>
        <p className={`${classNamePrefix}__info`}>
          Zweryfikuj swoją tożsamość:
        </p>

        <FormWithScrollToError form={form} onFinish={onSubmit}>
          <div>
            <FieldWrapper
              normalize={onlyDigits}
              name="pesel"
              rules={[
                {
                  required: true,
                  validator: validatePesel,
                },
              ]}
            >
              <FloatingLabelTextInput name="pesel" label="PESEL" />
            </FieldWrapper>

            <FieldWrapper
              name="birthCountry"
              initialValue={{ label: 'Polska', value: 'PL' }}
              rules={[
                {
                  required: true,
                  validator: validateCountry,
                },
              ]}
            >
              <FloatingLabelSelectInput
                options={ConutriesSelectOptions}
                label="Kraj urodzenia"
              />
            </FieldWrapper>

            <FieldWrapper
              name="citizenship"
              initialValue={{ label: 'Polska', value: 'PL' }}
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateCitizenship(_, value, 'PL'),
                },
              ]}
            >
              <FloatingLabelSelectInput
                options={ConutriesSelectOptions}
                label="Obywatelstwo"
              />
            </FieldWrapper>

            <FieldWrapper
              name="idCard"
              rules={[
                {
                  required: true,
                  validator: validateID,
                },
              ]}
            >
              <FloatingLabelTextInput
                name="idCard"
                label="Seria i numer dowodu osobistego"
              />
            </FieldWrapper>
          </div>

          <div className="required-group">
            <FieldWrapper
              label="Niniejszym, będąc świadomy odpowiedzialności karnej za złożenie fałszywego oświadczenia, oświadczam, że (prosimy zaznaczyć odpowiednią pozycję)"
              name="politicalStatus"
              rules={[
                {
                  required: true,
                  validator: validatePoliticalStatus,
                },
              ]}
            >
              <fieldset>
                {pepConsents.map(({ id, required, label }) => (
                  <Radio
                    isRequired={required}
                    key={id}
                    name="politicalStatus"
                    content={label}
                    value={id}
                  />
                ))}
              </fieldset>
            </FieldWrapper>
          </div>
          <div className={`${classNamePrefix}__button`}>
            <Button loading={isLoading} styleType="primary">
              Potwierdź
            </Button>
          </div>
        </FormWithScrollToError>
      </div>
    </div>
  );
};

export default IdentityView;
