import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { postAppConsent } from 'redux/data/thunk';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Store } from 'antd/lib/form/interface';
import { Checkbox, Form, FormInstance } from 'antd';
import Collapse from 'components/Collapse';
import './styles.scss';
import useConsents from 'hooks/useConsents';
import Button from 'components/Button';
import { routes } from 'routes';
import 'views/Consents/styles.scss';
import { Consent } from 'types';
import { ConsentTypeEnum } from 'types/enums';
import CollapseWithOutBorder from 'components/Collapse/collapseWithOutBorder';
const classNamePrefix: 'consents' = 'consents';
type ConsentFormData = Record<number, boolean>;

const ConsentsView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [checkAll, setCheckAll] = useState(false);
  const [form]: [FormInstance<ConsentFormData>] = Form.useForm();
  const { isLoading, app } = useAppSelector((state) => state.Data);
  const { standardConsents: consents, consentsValues } = useConsents();
  const appToken = app?.applicationDetails?.token;
  const questionnaire = useAppSelector(
    (state) => state.Data.appProgressTemp?.registrationSteps?.questionnaire
  );
  let consentsToDisplay: Consent[] = [];

  if (questionnaire) {
    const UnCommonConsents = consents.filter(
      (objA) => !consentsValues.find((objB) => objB.id === objA.id)
    );

    consentsToDisplay = UnCommonConsents;
  } else {
    consentsToDisplay = consents;
  }

  const { statementConsents, pepAndStandardConsents } =
    consentsToDisplay.reduce<{
      statementConsents: Consent[];
      pepAndStandardConsents: Consent[];
    }>(
      (acc, x) =>
        x.type === ConsentTypeEnum.statement
          ? { ...acc, statementConsents: [...acc.statementConsents, x] }
          : {
              ...acc,
              pepAndStandardConsents: [...acc.pepAndStandardConsents, x],
            },
      { statementConsents: [], pepAndStandardConsents: [] }
    );

  const onSubmit = (data: ConsentFormData) => {
    const formConsents = Object.keys(data)
      .filter((e) => e !== 'amount')
      .map((e) => ({ id: +e, checked: data[+e] }));

    dispatch(
      postAppConsent({
        token: appToken ?? '',
        consents: formConsents,
      })
    ).then((action) => {
      if (action?.payload?.status === 200) {
        navigate(routes.declarations);
      }
    });
  };

  const handleCheck = (e: CheckboxChangeEvent) => {
    const id = Number(e.target.id);
    form.setFieldValue(id.toString(), e.target.checked);
  };

  const handleCheckAll = (e: CheckboxChangeEvent) => {
    setCheckAll(e.target.checked);

    const output = consents.reduce((a: Store, item) => {
      a[item.id] = e.target.checked;
      return a;
    }, {});

    const temp = Object.entries(output)
      .filter((el) => {
        return (
          +el[0] !== statementConsents[0].id &&
          +el[0] !== statementConsents[1].id
        );
      })
      .reduce((acc: any, cur) => {
        acc[cur[0]] = cur[1];
        return acc;
      }, {});

    form.setFieldsValue(temp);
    form.validateFields();
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__inner`}>
        <p className={`${classNamePrefix}__info`}>Potwierdź wymagane zgody:</p>
        <Form
          form={form}
          className={classNamePrefix}
          onFinish={onSubmit}
          scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
          {statementConsents.map(({ id, label, shortDesc, required, step }) => (
            <Form.Item key={id} name={id} valuePropName="checked">
              <CollapseWithOutBorder
                key={id}
                id={id}
                label={shortDesc}
                text={label}
                required={required}
                onChange={handleCheck}
              />
            </Form.Item>
          ))}

          <div className="lead-consents">
            {!!pepAndStandardConsents.length && (
              <>
                <div className="lead-consents__select-all">
                  <Checkbox checked={checkAll} onChange={handleCheckAll} />
                  <span className="consent-step__label">
                    Zaznacz wszystkie poniższe zgody
                  </span>
                </div>
                {pepAndStandardConsents.map(
                  ({ id, label, shortDesc, required, step }) => (
                    <Form.Item key={id} name={id} valuePropName="checked">
                      <Collapse
                        key={id}
                        id={id}
                        label={shortDesc}
                        text={label}
                        required={required}
                        onChange={handleCheck}
                      />
                    </Form.Item>
                  )
                )}
              </>
            )}

            <div className={`${classNamePrefix}__button`}>
              <Button loading={isLoading} styleType="primary">
                Potwierdź
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ConsentsView;
