import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import Form, { FormInstance } from 'rc-field-form';
import { otpSend, otpConfirm, acceptOffer } from 'redux/data/thunk';
import Button from 'components/Button';
import LogoWealthon from 'components/LogoWealthon';
import SvgCloudMessage from 'components/Icons/SvgCloudMessage';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { OTPTypeEnum } from 'types/enums';
import SmsCodeInput from 'components/SmsCodeInput';
import FieldWrapper from 'components/FieldWrapper';
import { routes } from 'routes';

import 'views/SmsVerify/styles.scss';

type SmsVerifyForm = {
  password: string;
};

const classNamePrefix: 'sms-verify' = 'sms-verify';

const SmsVerifyView: React.FC = () => {
  const [form]: [FormInstance<SmsVerifyForm>] = Form.useForm();

  const isAuthLoading = useAppSelector((state) => state.Auth.isLoading);
  const {
    app,
    isLoading: isAppLoading,
    clientOffer,
    amount,
  } = useAppSelector((state) => state.Data);

  const appId = app?.applicationDetails?.id;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    appId &&
      dispatch(
        otpSend({
          applicationId: `${appId}`,
          type: OTPTypeEnum.INCREASE_LIMIT_CONFIRMATION,
        })
      );
  }, [dispatch, appId]);

  const onSubmit = React.useCallback(
    ({ password }: SmsVerifyForm) => {
      if (appId && password)
        dispatch(
          otpConfirm({
            applicationId: `${appId}`,
            type: OTPTypeEnum.INCREASE_LIMIT_CONFIRMATION,
            password,
          })
        ).then((res) => {
          if (res.payload?.status === 200) {
            dispatch(
              acceptOffer({
                reqId: `${app?.applicationDetails.id}/offer/${clientOffer?.id}/accept`,
                amountGranted: amount,
              })
            ).then((response) => {
              if (response.payload?.status === 200) {
                navigate(routes.complete);
              }
            });
          }
        });
    },
    [appId, dispatch, navigate]
  );

  const onResendSmsClick = () => {
    appId &&
      dispatch(
        otpSend({
          applicationId: `${appId}`,
          type: OTPTypeEnum.INCREASE_LIMIT_CONFIRMATION,
        })
      );
  };

  return (
    <div className={classNamePrefix}>
      <LogoWealthon marginBottom={82} />
      <div className={`${classNamePrefix}__icon-container`}>
        <SvgCloudMessage width={60} height={60} />
      </div>
      <div className={`${classNamePrefix}__title`}>Podpisanie umowy</div>
      <span className={`${classNamePrefix}__info`}>
        Wpisz kod otrzymany SMSem:
      </span>
      <div className={`${classNamePrefix}__sms-input-wrapper`}>
        <FormWithScrollToError form={form} onFinish={onSubmit}>
          <FieldWrapper name={'password' as keyof SmsVerifyForm}>
            <SmsCodeInput error="" onEnter={form.submit} onSubmit={onSubmit} />
          </FieldWrapper>
        </FormWithScrollToError>

        <div className={`${classNamePrefix}__resend`}>
          <span>Nie dotarł SMS?</span>
          <Button
            styleType="tertiary"
            style={{ color: '#000080', textDecoration: 'none' }}
            onClick={onResendSmsClick}
            disabled={isAuthLoading || isAppLoading}
          >
            Wyślij ponownie
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SmsVerifyView;
