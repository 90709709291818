import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  checkIfHasLimit,
  getApp,
  getAppProgress,
  getClientOffer,
  getOffer,
} from 'redux/data/thunk';

import {
  UserData,
  UserRegisterSteps,
  Application,
  Company,
  Offer,
} from 'types';
import {
  ApplicationStatusEnum,
  CompanyStatusEnum,
  ProductTypesEnum,
} from 'types/enums';
import { getApplicationResignationDate } from 'helpers/applicationData';
import { ApplicationProgress } from 'redux/data/types';

import { getUser, getUserProgress } from 'redux/account/thunk';
import { finish, start } from 'redux/data/reducer';
import { setAuthToken } from 'redux/auth/reducer';
import { postLogin } from 'redux/auth/thunk';

import { routes } from 'routes';
import queryString from 'query-string';

const useLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isStarted } = useAppSelector((state) => state.Data);

  const logIn = React.useCallback(async () => {
    const isLoggedAction = await dispatch(postLogin());
    const isLogged =
      isLoggedAction.payload?.status === 200 &&
      isLoggedAction?.payload?.data?.token;

    return { isLogged };
  }, [dispatch]);

  const getUserData = React.useCallback(async () => {
    const isUserAction = await dispatch(getUser());
    const userData =
      isUserAction.payload?.status === 200 && isUserAction?.payload?.data;
    return userData || null;
  }, [dispatch]);

  const getProgressUser = React.useCallback(async () => {
    const userProgressAction = await dispatch(getUserProgress());
    if (
      userProgressAction?.payload?.status === 200 &&
      userProgressAction?.payload.data
    ) {
      return userProgressAction?.payload?.data?.registrationSteps;
    }
    return null;
  }, [dispatch]);

  const getAppId = React.useCallback(async () => {
    const hasLimitProductAction = await dispatch(
      checkIfHasLimit({ reqId: `${ProductTypesEnum.limitProduct}` })
    );

    if (
      hasLimitProductAction?.payload?.status === 200 &&
      !!hasLimitProductAction?.payload?.data?.exists
    ) {
      return {
        appId: hasLimitProductAction.payload.data.products[0],
        hasLimit: true,
      };
    }

    const hasLimitAppAction = await dispatch(
      checkIfHasLimit({ reqId: `${ProductTypesEnum.limitApp}` })
    );

    if (
      hasLimitAppAction?.payload?.status === 200 &&
      !!hasLimitAppAction?.payload?.data?.exists
    ) {
      return {
        appId: hasLimitAppAction.payload.data.products[0],
        hasLimit: false,
      };
    }
    return { appId: null, hasLimit: false };
  }, [dispatch]);

  const getApplication = React.useCallback(
    async (appId: string) => {
      const appAction = await dispatch(getApp({ reqId: appId }));
      if (appAction?.payload?.status === 200 && appAction?.payload?.data) {
        return appAction?.payload?.data;
      }

      return null;
    },
    [dispatch]
  );

  const getProgressApplication = React.useCallback(
    async (appId: string) => {
      const appProgressAction = await dispatch(
        getAppProgress({
          reqId: `${appId}/progress`,
        })
      );
      if (
        appProgressAction?.payload?.status === 200 &&
        appProgressAction?.payload.data
      ) {
        return appProgressAction?.payload?.data;
      }
      return null;
    },
    [dispatch]
  );

  const navigateToStep = React.useCallback(
    async (
      resignationDate: Number | null,
      hasLimit: boolean,
      userData: UserData | null,
      userProgress: UserRegisterSteps | null,
      appProgress?: ApplicationProgress | null,
      appData?: Application | null,
      status?: string | null,
      appId?: string | null,
      offer?: Offer | undefined
    ) => {
      switch (true) {
        // case !offer?.isAccepted:
        //   navigate(routes.acceptOffer);
        //   break;

        // case offer?.isAccepted:
        //   navigate(routes.smsVerify);
        //   break;

        // case userData?.user?.hasVerifiedEmail:
        //   navigate(routes.complete);
        //   break;

        default:
          navigate(routes.acceptOffer);
          break;
      }
    },
    [navigate]
  );

  const setDefaultData = React.useCallback(async () => {
    dispatch(start());

    const { token } = queryString.parse(location?.search);

    if (token) {
      dispatch(setAuthToken(`${token}`));
    }

    const { isLogged } = await logIn();
    const { appId, hasLimit } = await getAppId();
    if (isLogged) {
      const userData = await getUserData();
      if (userData?.client.isPep) {
        navigate(routes.contact);
        dispatch(finish());
        return;
      }
      if (
        userData?.company?.statusString === CompanyStatusEnum.SUSPENDED ||
        userData?.company?.statusString === CompanyStatusEnum.INACTIVE
      ) {
        navigate(routes.nip);
        dispatch(finish());
        return;
      }
      const userProgress = await getProgressUser();

      if (appId) {
        const appProgress = await getProgressApplication(appId);

        const application = await getApplication(appId);

        const offer = await dispatch(getClientOffer()).then((response) => {
          if (response.payload?.status === 200) {
            const data = response.payload.data.offer;
            if (data) {
              if ('maxAmount' in data) {
                return data;
              } else {
                return undefined;
              }
            }
          }
        });

        const status = application?.applicationDetails?.status ?? null;

        const isResignedApplication =
          status === ApplicationStatusEnum.resignation;
        await dispatch(finish());
        await navigateToStep(
          getApplicationResignationDate(application, userProgress),
          hasLimit,
          userData,
          userProgress,
          isResignedApplication ? null : appProgress,
          isResignedApplication ? null : application,
          status ? status : null,
          appId,
          offer
        );
      } else {
        dispatch(finish());
        await navigateToStep(
          null,
          hasLimit,
          userData,
          userProgress,
          null,
          null,
          null,
          appId
        );
      }
    } else {
      dispatch(finish());
      navigate(routes.acceptOffer);
    }
  }, [
    getAppId,
    getApplication,
    getProgressApplication,
    navigateToStep,
    getProgressUser,
    getUserData,
    navigate,
    dispatch,
    logIn,
    location,
  ]);

  React.useEffect(() => {
    !isStarted && setDefaultData();
  }, [isStarted, setDefaultData]);
};

export default useLogin;
