import handleThunk from 'redux/thunkHandler';
import {
  PostIdentityData,
  PostIdentitySuccess,
  PostIdentityFailure,
  GetAppData,
  GetAppSuccess,
  GetAppFailure,
  PostAppData,
  PostAppSuccess,
  PostAppFailure,
  GetOfferData,
  GetOfferSuccess,
  GetOfferFailure,
  AcceptOfferData,
  AcceptOfferSuccess,
  AcceptOfferFailure,
  PostHasLimitData,
  PostHasLimitSuccess,
  PostHasLimitFailure,
  GetProgressData,
  GetProgressSuccess,
  GetProgressFailure,
  GetConsentData,
  GetConsentSuccess,
  GetConsentFailure,
  GetConsentValuesData,
  GetConsentValuesSuccess,
  GetConsentValuesFailure,
  PostConsentData,
  PostConsentSuccess,
  PostConsentFailure,
  OTPSendAction,
  OTPSendSuccess,
  OTPSendFailure,
  OTPConfirmAction,
  OTPConfirmSuccess,
  OTPConfirmFailure,
  PostStatementData,
  PostStatementSuccess,
  PostStatementFailure,
  PostKYCStatusAction,
  PostKYCStatusSuccess,
  PostKYCStatusFailure,
  PutAppData,
  PutAppSuccess,
  PutAppFailure,
  CancelOfferData,
  CancelOfferSuccess,
  CancelOfferFailure,
  LimitInfoSucess,
  LimitInfoFailure,
  GetOfferClientData,
  GetOfferClientSuccess,
  GetOfferClientFailure,
} from 'redux/data/types';

export const postIdentity = handleThunk<
  PostIdentityData,
  PostIdentitySuccess,
  PostIdentityFailure
>('data/POST_IDENTITY', '/user/identity-data', 'POST');

export const getApp = handleThunk<GetAppData, GetAppSuccess, GetAppFailure>(
  'data/GET_APP',
  '/application'
);

export const postApp = handleThunk<PostAppData, PostAppSuccess, PostAppFailure>(
  'data/POST_APP',
  '/application',
  'POST'
);

export const putApp = handleThunk<PutAppData, PutAppSuccess, PutAppFailure>(
  'data/PUT_APP',
  '/application',
  'PUT'
);

export const getOffer = handleThunk<
  GetOfferData,
  GetOfferSuccess,
  GetOfferFailure
>('data/GET_OFFER', '/application');

export const getClientOffer = handleThunk<
  void,
  GetOfferClientSuccess,
  GetOfferClientFailure
>('data/GET_CLIENT_OFFER', '/client/offer');

export const acceptOffer = handleThunk<
  AcceptOfferData,
  AcceptOfferSuccess,
  AcceptOfferFailure
>('data/ACCEPT_OFFER', '/application', 'POST');

export const canelOffer = handleThunk<
  CancelOfferData,
  CancelOfferSuccess,
  CancelOfferFailure
>('data/CANCEL_OFFER', '/application', 'POST');

export const checkIfHasLimit = handleThunk<
  PostHasLimitData,
  PostHasLimitSuccess,
  PostHasLimitFailure
>('auth/GET_HAS_LIMIT', '/product/exists');

export const getAppProgress = handleThunk<
  GetProgressData,
  GetProgressSuccess,
  GetProgressFailure
>('auth/GET_APP_PROGRESS', '/application');

export const getAppConsent = handleThunk<
  GetConsentData,
  GetConsentSuccess,
  GetConsentFailure
>('auth/GET_APP_CONSENTS', '/consent');

export const getAppConsentValues = handleThunk<
  GetConsentValuesData,
  GetConsentValuesSuccess,
  GetConsentValuesFailure
>('auth/GET_APP_CONSENTS_VALUES', '/application');

export const postAppConsent = handleThunk<
  PostConsentData,
  PostConsentSuccess,
  PostConsentFailure
>('auth/POST_APP_CONSENTS', '/consent', 'POST');

export const otpSend = handleThunk<
  OTPSendAction,
  OTPSendSuccess,
  OTPSendFailure
>('otp/POST_OTP_SEND', '/otp/send', 'POST');

export const otpConfirm = handleThunk<
  OTPConfirmAction,
  OTPConfirmSuccess,
  OTPConfirmFailure
>('otp/POST_OTP_CONFIRM', '/otp/confirm', 'POST');

export const postStatement = handleThunk<
  PostStatementData,
  PostStatementSuccess,
  PostStatementFailure
>('application/POST_STATEMENT', '/statement', 'POST');

export const postKYCStatus = handleThunk<
  PostKYCStatusAction,
  PostKYCStatusSuccess,
  PostKYCStatusFailure
>('application/POST_KYC_STATUS', '/client/kyc/status', 'POST');

export const getLimitInfo = handleThunk<
  void,
  LimitInfoSucess,
  LimitInfoFailure
>('application/GET_LIMIT_INFO', '/settings', 'GET');
