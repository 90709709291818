import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import { getData } from 'redux/account/thunk';
import Form, { FormInstance } from 'rc-field-form';
import FieldWrapper from 'components/FieldWrapper';
import Button from 'components/Button';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { validateNip } from 'helpers/validators';
import { routes } from 'routes';
import useNotification from 'hooks/useNotification';
import 'views/Nip/styles.scss';
import { showError } from 'redux/account/reducer';
import { ErrorNipEnum } from 'types/enums';
import { checkIsClientHaveActiveCard } from 'redux/auth/thunk';
import { useKeyboardCloseOnUnmount } from 'hooks/useKeyboardCloseOnUnmount';
import FloatingLabelNipInput from 'components/Inputs/FloatingLabelInputs/FloatingLabelNipInput/FloatingLabelNipInput';
import { useCeidgContext } from 'context/ceidgResponseContext';

type NipFormData = {
  nip: string;
};

const classNamePrefix: 'nip' = 'nip';

const NipView: React.FC = () => {
  const [form]: [FormInstance<NipFormData>] = Form.useForm();
  const { companyData } = useAppSelector((state) => state.Account);
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = useKeyboardCloseOnUnmount();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notify = useNotification();
  const { storeCeidgValue } = useCeidgContext();

  const onSubmit = ({ nip }: NipFormData) => {
    setIsLoading(true);
    dispatch(getData({ nip })).then((action) => {
      if (action.payload?.status === 200) {
        if (action.payload.data.source !== 'ceidg') {
          notify(
            'Karta Wealthon skierowana jest tylko dla jednoosobowych działalności gospodarczych. Jeżeli szukasz finansowania zapraszamy do kontaktu z nami lub odwiedzenia strony wealthon.com po więcej informacji.',
            10
          );
          return;
        }

        if (action.payload.data.company.nip !== nip) {
          navigate(routes.contact);
          dispatch(showError(ErrorNipEnum.NIP_IS_NOT_VALID));
          return;
        }
        const ceidgResponse = action.payload.data;

        if (ceidgResponse.company.status_string === 'SUSPENDED') {
          notify(
            'Błąd podczas wysyłania danych. Nie posiadasz aktywnej działaności gospodarczej, nie możemy rozpatrzyć Twojego wniosku.',
            10
          );
          setIsLoading(false);
          return;
        }

        storeCeidgValue(ceidgResponse);

        dispatch(
          checkIsClientHaveActiveCard({
            nip: ceidgResponse.company.nip,
          })
        ).then((action) => {
          if (action.payload?.status === 400) {
            let errorMessage = [...(action.payload.errors as string[])];
            notify(errorMessage[0], 10);
            return;
          }
          if (action.payload?.status === 200) {
            navigate(routes.verify);
          }
        });
      }
    });
  };

  React.useEffect(() => {
    if (form && companyData?.nip) form.setFieldsValue({ nip: companyData.nip });
  }, [form, companyData]);

  return (
    <div className={classNamePrefix} ref={ref}>
      <div className={`${classNamePrefix}__inner`}>
        <p className={`${classNamePrefix}__info`}>
          Wprowadź swój NIP w celu weryfikacji Twoich danych:
        </p>
        <FormWithScrollToError form={form} onFinish={onSubmit}>
          <FieldWrapper
            // normalize={NIP}
            name="nip"
            rules={[
              {
                required: true,
                validator: validateNip,
              },
            ]}
          >
            <FloatingLabelNipInput name="nip" label="NIP" />
          </FieldWrapper>

          <div className={`${classNamePrefix}__button`}>
            <Button loading={isLoading}>Potwierdź</Button>
          </div>
        </FormWithScrollToError>
      </div>
    </div>
  );
};

export default NipView;
