import * as React from 'react';

const SvgPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 11.103V20.8715"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.8886 15.9873H11.1108"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.2475 2.66663H9.75222C5.39666 2.66663 2.6665 5.7494 2.6665 10.1135V21.8864C2.6665 26.2505 5.38396 29.3333 9.75222 29.3333H22.2475C26.6157 29.3333 29.3332 26.2505 29.3332 21.8864V10.1135C29.3332 5.7494 26.6157 2.66663 22.2475 2.66663Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgPlus;
