import * as React from 'react';

const SvgAuthologyError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="73"
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.6846 29.6094L29.3086 43.9854"
      stroke="#DF3C3C"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.6888 43.9945L29.3008 29.6035"
      stroke="#DF3C3C"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M49.502 9.07617H23.495C14.432 9.07617 8.75 15.4932 8.75 24.5742V49.0782C8.75 58.1592 14.405 64.5762 23.495 64.5762H49.499C58.592 64.5762 64.25 58.1592 64.25 49.0782V24.5742C64.25 15.4932 58.592 9.07617 49.502 9.07617Z"
      stroke="#DF3C3C"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgAuthologyError;
