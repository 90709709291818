import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { postKYCStatus } from 'redux/data/thunk';
import { KYCStatusEnum, PostMessageTypeEnum } from 'types/enums';
import LogoWealthon from 'components/LogoWealthon';
import SvgTimeout from 'components/Icons/SvgTimeout';

import Button from 'components/Button';

import { postWebviewMsg } from 'helpers/postWebviewMessage';

import 'views/ExpiredOffer/styles.scss';

const classNamePrefix: 'expired-offer' = 'expired-offer';

const ExpiredOfferView: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.Data);

  const onCancel = () => {
    dispatch(postKYCStatus({ status: KYCStatusEnum.REJECTED })).then(() =>
      postWebviewMsg(PostMessageTypeEnum.CANCELLED)
    );
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <LogoWealthon />
        <SvgTimeout />
        <div className={`${classNamePrefix}__title`}>Twoja oferta wygasła</div>
        <div className={`${classNamePrefix}__description`}>
          Złóż wniosek ponownie. Pamiętaj, że w razie trudności z jego
          uzupełnieniem możesz uzyskać pomoc pisząc na:
        </div>
        <a
          className={`${classNamePrefix}__link`}
          href="biuro@wealthon.com"
          target="_blank"
        >
          biuro@wealthon.com
        </a>
      </div>
      <Button loading={isLoading} onClick={onCancel} styleType={'secondary'}>
        Powrót do ekranu głównego
      </Button>
    </div>
  );
};

export default ExpiredOfferView;
