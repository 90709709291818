import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import Button from 'components/Button';
import SvgWealthonLogo from 'components/Icons/SvgWealthonLogo';
import SvgVerifyLocalContact from 'components/Icons/SvgVerifyLocalContact';
import 'views/VerifyLocalContact/styles.scss';

const classNamePrefix: 'verify-local-contact' = 'verify-local-contact';

const VerifyLocalContactView: React.FC = () => {
  const navigate = useNavigate();

  const onBackToOnlineVerifyClick = () => {
    navigate(routes.verifyOnline);
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <div className={`${classNamePrefix}__logo_container`}>
          <SvgWealthonLogo />
        </div>
        <SvgVerifyLocalContact />
        <div className={`${classNamePrefix}__title`}>Skontaktuj się z nami</div>
      </div>
      <div className={`${classNamePrefix}__information-container`}>
        <div className={`${classNamePrefix}__description`}>
          Aby zweryfikować twoją tożsamość zapraszamy do siedziby Wealthon.
        </div>
        <div className={`${classNamePrefix}__description`}>
          W celu umowienia wizyty wyślij e-mail na:{' '}
          <a
            href="mailto: biuro@wealthon.com"
            className={`${classNamePrefix}__email`}
          >
            biuro@wealthon.com
          </a>
        </div>
        <div className={`${classNamePrefix}__description `}>
          Infolinia: 517 889 699
        </div>
      </div>
      <div className={`${classNamePrefix}__buttons`}>
        <Button styleType="secondary" onClick={onBackToOnlineVerifyClick}>
          Powrót do weryfikacji online
        </Button>
      </div>
    </div>
  );
};

export default VerifyLocalContactView;
