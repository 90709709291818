import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SourceEnum } from 'types/enums';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { postStatement } from 'redux/data/thunk';
import { isEmpty } from 'helpers/global';

import Form, { FormInstance } from 'rc-field-form';

import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import FormWithScrollToError from 'components/FormWithScrollToError';

import { routes } from 'routes';

import 'views/Declarations/styles.scss';
import { handleScrollToInput } from 'helpers/scrollToInput';

type DeclarationFormData = {
  wealthSources: SourceEnum[];
  wealthSourcesWealthonRelated: SourceEnum[];
};

const classNamePrefix: 'declarations' = 'declarations';

const DeclarationsView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, app } = useAppSelector((state) => state.Data);

  const [form]: [FormInstance<DeclarationFormData>] = Form.useForm();

  const [wealthSources, setWealthSources] = React.useState<string[]>([]);
  const [wealthSourcesWealthonRelated, setWealthSourcesWealthonRelated] =
    React.useState<string[]>([]);
  const [touch, setTouch] = React.useState<boolean>(false);

  const onSubmit = () => {
    setTouch(true);

    if (isEmpty(wealthSources) || isEmpty(wealthSourcesWealthonRelated)) {
      handleScrollToInput({ ref, className: 'error' });
      return;
    }

    if (!isEmpty(wealthSources) && !isEmpty(wealthSourcesWealthonRelated)) {
      dispatch(
        postStatement({
          wealthSources,
          wealthSourcesWealthonRelated,
          token: app?.applicationDetails.token ?? '',
        })
      ).then((action) => {
        if (action?.payload?.status === 200) {
          navigate(routes.verifyOnline);
        }
      });
    }
  };

  const arr = (Object.keys(SourceEnum) as (keyof typeof SourceEnum)[]).map(
    (key) => {
      return { label: SourceEnum[key], key };
    }
  );

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__inner`} ref={ref}>
        <p className={`${classNamePrefix}__info`}>
          Złóż oświadczenie majątkowe:
        </p>

        <FormWithScrollToError form={form} onFinish={onSubmit}>
          <div>
            <p>Oświadczam, że źródła całego mojego majątku są następujące:</p>
            {arr.map(({ label, key }) => (
              <CheckBox
                key={key}
                checked={wealthSources.includes(key)}
                content={label}
                isRequired={false}
                onChange={() =>
                  setWealthSources(
                    wealthSources.includes(key)
                      ? wealthSources.filter((e) => e !== key)
                      : [...wealthSources, key]
                  )
                }
              />
            ))}
            {touch && isEmpty(wealthSources) && (
              <div className="error">
                Prosze podać przynajmniej jedno źródło majątku
              </div>
            )}

            <p className={`${classNamePrefix}__statement`}>
              Oświadczam, że źródła pochodzenia środków pieniężnych (wartości
              wykorzystywanych przeze mnie w relacji z Wealthon S.A. są
              następujące:
            </p>

            {arr.map(({ label, key }) => (
              <CheckBox
                key={key}
                content={label}
                checked={wealthSourcesWealthonRelated.includes(key)}
                isRequired={false}
                onChange={() =>
                  setWealthSourcesWealthonRelated(
                    wealthSourcesWealthonRelated.includes(key)
                      ? wealthSourcesWealthonRelated.filter((e) => e !== key)
                      : [...wealthSourcesWealthonRelated, key]
                  )
                }
              />
            ))}
          </div>
          {touch && isEmpty(wealthSourcesWealthonRelated) && (
            <div className="error">
              Prosze podać przynajmniej jedno źródło pochodzenia środków
              pieniężnych
            </div>
          )}

          <div className={`${classNamePrefix}__button`}>
            <Button loading={isLoading} styleType="primary">
              Potwierdź
            </Button>
          </div>
        </FormWithScrollToError>
      </div>
    </div>
  );
};

export default DeclarationsView;
