import React, { Component } from 'react';
import Select, { components } from 'react-select';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

type MyProps = {
  options: any;
  label: string;
  onChange?: any;
  value?: any;
};

export class FloatingLabelSelectInput extends Component<MyProps> {
  render() {
    const { onChange, value } = this.props;
    return (
      <Select
        className="basic-single"
        classNamePrefix="select"
        name="color"
        isSearchable={false}
        defaultValue={{ label: 'Polska', value: 'PL' }}
        value={value}
        options={this.props.options}
        onChange={onChange}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        placeholder={this.props.label}
        styles={{
          container: (provided, state) => ({
            ...provided,
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: 54,
            borderColor: state.isFocused ? '#4d4d4d' : '#c6c6c6',
            boxShadow: 'none',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: 'visible',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            marginTop: 18,
            color: 'black',
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            width: 0,
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            top: state.hasValue || state.selectProps.inputValue ? -2 : '20%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
            color:
              state.hasValue || state.selectProps.inputValue
                ? '#9e9e9e'
                : '#808080',
          }),
        }}
      />
    );
  }
}
