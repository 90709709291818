const routes = {
  /*   -   */ home: '/',
  /*   3   */ nip: '/nip',
  /*   3   */ verify: '/weryfikacja',
  /*   5   */ identity: '/tozsamosc',
  /*   6a  */ limit: '/limit',
  /*  6b,9 */ consents: '/zgody',
  /*   6c  */ declarations: '/oswiadczenie-majatkowe',
  /*   28  */ verifyOnline: '/weryfikacja-online',
  /*   29  */ verifyLocal: '/weryfikacja-stacjonarna',
  /*   30  */ verifyLocalContact: '/weryfikacja-stacjonarna-kontakt',
  /*   7   */ authologic: '/authologic',
  /*   7   */ authologicStatus: '/authologic-status',
  /*   error   */ authologicError: '/authologic-error',
  /*   repeat   */ authologicRepeat: '/authologic-repeat',
  /*   7b   */ authologicFlow: '/authologic-flow',
  /*   8   */ kontomatik: '/kontomatik',
  /*   11  */ offerPending: '/przygotowywanie-oferty',
  /*   13  */ expiredOffer: '/oferta-wygasla',
  /*   14a  */ wealthonCardResignation: '/karta-wealthon-rezygnacja',
  /*   14b  */ wealthonCardNoLimit: '/karta-wealthon-brak-limitu',
  /*   26  */ contact: '/kontakt',
  /*   12  */ acceptOffer: '/akceptacja-oferty',
  /*   27  */ expiredApp: '/wniosek-wygasl',
  /*   4   */ smsVerify: '/weryfikacja-sms',
  /*   24  */ emailConfirmation: '/potwierdzenie-email',
  /*   23  */ noEmailConfirmation: '/brak-potwierdzenia-email',
  /*   22  */ complete: '/zakonczenie',
  /*   manual  */ amlManual: '/aml-manual',
  /*   continue web propsal  */ continueProposal: '/kontynuuj-wniosek',
};

const apiRoutes = {
  external: 'https://wf-gate.etd24.pl/auth/external',
};

export { routes, apiRoutes };
