import * as React from 'react';
import { FormInstance } from 'rc-field-form';
import InputNumber, { InputNumberProps } from 'rc-input-number';
import { LimitFormData } from 'views/Limit';
import 'components/NumberInput/styles.scss';
import SvgMinus from 'components/Icons/SvgMinus';
import SvgPlus from 'components/Icons/SvgPlus';

type Props = {
  form: FormInstance<LimitFormData>;
  onAmountChange: (val: number) => void;
  amount: number;
  min: number;
  max: number;
  isRequired: boolean;
  isMarkdown?: boolean;
} & Omit<InputNumberProps, 'form' | 'min' | 'max'>;

const classNamePrefix: 'input-number' = 'input-number';

const NumberInput: React.FC<Props> = ({
  form,
  onAmountChange,
  amount,
  min,
  max,
  step,
  isRequired = false,
  isMarkdown = false,
  ...inputProps
}) => {
  const onContentBlur = (evt: any) => {
    onChange(evt.currentTarget.innerHTML);
  };

  const onChange = (val: number | string | any) => {
    if (val === '') {
      return;
    }
    if (isNaN(val)) {
      val = 500;
    }
    if (val < min) {
      val = 500;
    }
    const rounded = Math.round(+val / 100) * 100;
    if (min && rounded < min) onAmountChange(min);
    else if (max && rounded > max) onAmountChange(max);
    else onAmountChange(rounded);
  };

  return (
    <div
      className={`${classNamePrefix} ${
        isRequired ? `${classNamePrefix}--required` : ''
      }`}
    >
      <button
        className={`${classNamePrefix}__button ${classNamePrefix}__button--minus`}
        type="button"
        disabled={!!min && amount - 100 < min}
        onClick={() => onChange(amount - 100)}
      >
        <SvgMinus />
      </button>
      <div className={`${classNamePrefix}__input__wrapper`}>
        {/* <input
          value={amount}
          min={min}
          max={max}
          step={step}
          onChange={(val) => onChange(val.target.value)}
          className={`${classNamePrefix}__input`}
          id="test"
          pattern="[0-9]+"
          type="number"
        /> */}

        {/* 
          you can ask, why i am doing this here, its my workaround for dynamic change width of input 
          Once, me and god knew how it works, now only god knows
          */}
        <div
          className={`${classNamePrefix}__input`}
          contentEditable
          onBlur={onContentBlur}
          dangerouslySetInnerHTML={{ __html: `${amount}` }}
        />
        <span className={`${classNamePrefix}__currency_suffix`}>PLN</span>
      </div>

      <button
        className={`${classNamePrefix}__button ${classNamePrefix}__button--plus`}
        type="button"
        disabled={!!max && amount + 100 > max}
        onClick={(e) => onChange(amount + 100)}
      >
        <SvgPlus />
      </button>
    </div>
  );
};

export default NumberInput;
function sanitizeHtml(
  innerHTML: any,
  sanitizeConf: { allowedTags: string[]; allowedAttributes: { a: string[] } }
): React.SetStateAction<string> {
  throw new Error('Function not implemented.');
}
