import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import 'components/Radio/styles.scss';

type Props = {
  content: string;
  isRequired: boolean;
  isMarkdown?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const classNamePrefix: 'radio' = 'radio';

const Radio: React.FC<Props> = ({
  content,
  isRequired = false,
  isMarkdown = false,
  ...radioProps
}) => (
  <label
    className={`${classNamePrefix} ${
      isRequired ? `${classNamePrefix}--required` : ''
    }`}
  >
    <input
      className={`${classNamePrefix}__input`}
      type="radio"
      {...radioProps}
    />
    <span className="checkmark" />
    {isMarkdown ? (
      <ReactMarkdown
        className={`${classNamePrefix}__content`}
        linkTarget="_blank"
      >
        {content}
      </ReactMarkdown>
    ) : (
      <div
        className={`${classNamePrefix}__content`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    )}
  </label>
);

export default Radio;
