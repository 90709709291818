import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { postKYCStatus } from 'redux/data/thunk';
import { KYCStatusEnum, PostMessageTypeEnum } from 'types/enums';
import { ErrorNipEnum } from 'types/enums';
import LogoWealthon from 'components/LogoWealthon';
import SvgBoardWithCross from 'components/Icons/SvgBoardWithCross';

import { postWebviewMsg } from 'helpers/postWebviewMessage';

import 'views/ContactBok/styles.scss';
import Button from 'components/Button';

const classNamePrefix: 'contact-bok' = 'contact-bok';

const ContactBokView: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.Data);

  const { error } = useAppSelector((state) => state.Account);

  const onCancel = () => {
    dispatch(postKYCStatus({ status: KYCStatusEnum.REJECTED })).then(() =>
      postWebviewMsg(PostMessageTypeEnum.CANCELLED)
    );
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <LogoWealthon />
        <SvgBoardWithCross />
        <div className={`${classNamePrefix}__title`}>
          {error === ErrorNipEnum.NIP_IS_NOT_VALID
            ? 'Niestety nie możemy zarejestrować Twoich danych'
            : 'Niestety nie możesz dalej kontynuować składania wniosku.'}
        </div>
        <div className={`${classNamePrefix}__description`}>
          {error === ErrorNipEnum.NIP_IS_NOT_VALID
            ? 'Wpisz prawidłowy NIP lub skontaktuj się z nami pisząc na adres'
            : 'W przypadku pytań prosimy o kontakt mailowy na adres'}
        </div>
        <a
          className={`${classNamePrefix}__link`}
          href="mailto:biuro@wealthon.com"
        >
          kontakt@wealthon.com
        </a>
      </div>
      <Button loading={isLoading} onClick={onCancel} styleType={'secondary'}>
        Powrót do ekranu głównego
      </Button>
    </div>
  );
};

export default ContactBokView;
