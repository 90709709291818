import * as React from 'react';
import { routes } from 'routes';
import LogoWealthon from 'components/LogoWealthon';
import Button from 'components/Button';
import 'views/Authologic/AuthologicErrorView/styles.scss';
import SvgAuthologyRepeat from 'components/Icons/SvgAuthologyRepeat';
import { getUser } from 'redux/account/thunk';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { GetUserSuccessData } from 'redux/account/types';

const classNamePrefix: 'repeat' = 'repeat';

const AuthologicRepeatVerification: React.FC = () => {
  const dispatch = useAppDispatch();
  const [amlReason, setAmlReason] = React.useState<
    GetUserSuccessData | false
  >();

  const getUserData = React.useCallback(async () => {
    const isUserAction = await dispatch(getUser());
    const userData =
      isUserAction.payload?.status === 200 && isUserAction?.payload?.data;
    setAmlReason(userData);
    return userData || null;
  }, [dispatch]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <LogoWealthon />
        <SvgAuthologyRepeat />
        <div className={`${classNamePrefix}__title`}>Niepowodzenie</div>
        <div className={`${classNamePrefix}__description`}>
          {amlReason && <span> {amlReason.client.amlReason}</span>}
        </div>
      </div>
      <div className={`${classNamePrefix}__button-wrapper`}>
        <Button to={routes.authologic} styleType={'primary'}>
          Ponów weryfikację online
        </Button>
        <Button
          to={routes.verifyLocal}
          styleType={'tertiary'}
          style={{ color: '#E5325A' }}
        >
          Weryfikacja stacjonarna
        </Button>
      </div>
    </div>
  );
};

export default AuthologicRepeatVerification;
