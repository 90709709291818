import * as React from 'react';
import { CeidgData } from 'types';

type Props = {
  data: CeidgData;
};

const classNamePrefix: 'ceidg-data' = 'ceidg-data';

const CeidgDataView: React.FC<Props> = ({ data }) => (
  <div className={classNamePrefix}>
    <span>Nazwa firmy</span>
    <h3>{data.companyName}</h3>

    <span>Imię</span>
    <h3>{data.firstName}</h3>

    <span>Nazwisko</span>
    <h3>{data.lastName}</h3>

    <span>Ulica</span>
    <h3>{data.street}</h3>

    <span>Nr budynku</span>
    <h3>{data.buildingNo}</h3>

    <span>Nr lokalu</span>
    <h3>{data?.apartmentNo ?? '-'}</h3>

    <span>Kod pocztowy</span>
    <h3>{data.postCode}</h3>

    <span>Miejscowość</span>
    <h3>{data.city}</h3>
  </div>
);

export default CeidgDataView;
