import { useState } from 'react';
import './index.scss';

interface Props {
  label: string;
  value?: string;
  name: string;
}

const FloatingLabelNipInput = (props: any) => {
  const [focus, setFocus] = useState(false);
  const { label, value, name, onChange, ...rest } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <input
        onChange={onChange}
        value={value}
        {...rest}
        name={name}
        type="text"
        pattern="[0-9]*"
        inputmode="numeric"
        className="label-float-input"
      />

      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelNipInput;
