import * as React from 'react';
import FieldWrapper from 'components/FieldWrapper';

import {
  validateApartmentNo,
  validatePostCode,
  validateStreet,
  validateCity,
} from 'helpers/validators';
import { postCode } from 'helpers/formatters';
import FloatingLabelTextInput from 'components/Inputs/FloatingLabelInputs/FloatingLabelTextInput/FloatingLabelTextInput';

const classNamePrefix: 'correspondence-form' = 'correspondence-form';

const CorrespondenceAddressForm: React.FC = () => (
  <div className={classNamePrefix}>
    <FieldWrapper
      name="street"
      rules={[
        {
          required: true,
          validator: (_, value) => validateStreet(_, value),
        },
      ]}
    >
      <FloatingLabelTextInput name="Ulica" label="Ulica" />
    </FieldWrapper>
    <FieldWrapper
      name="buildingNo"
      rules={[
        {
          required: true,
          validator: (_, value) => validateApartmentNo(_, value),
        },
      ]}
    >
      <FloatingLabelTextInput name="Nr budynku" label="Nr budynku" />
    </FieldWrapper>
    <FieldWrapper name="apartmentNo">
      <FloatingLabelTextInput name="Nr lokalu" label="Nr lokalu" />
    </FieldWrapper>
    <FieldWrapper
      name="postCode"
      normalize={postCode}
      rules={[
        {
          required: true,
          validator: (_, value) => validatePostCode(_, value),
        },
      ]}
    >
      <FloatingLabelTextInput name="Kod pocztowy" label="Kod pocztowy" />
    </FieldWrapper>
    <FieldWrapper
      name="city"
      rules={[
        {
          required: true,
          validator: (_, value) => validateCity(_, value),
        },
      ]}
    >
      <FloatingLabelTextInput name="Miejscowość" label="Miejscowość" />
    </FieldWrapper>
  </div>
);

export default CorrespondenceAddressForm;
