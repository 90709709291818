import * as React from 'react';
import { Field } from 'rc-field-form';
import { FieldProps } from 'rc-field-form/es/Field';
import MyField from 'components/FieldWrapper/field';

import 'components/FieldWrapper/styles.scss';

type Props = {
  label?: string;
  disabled?: boolean;
  children: React.ReactElement;
} & FieldProps;

const FieldWrapper: React.FC<Props> = ({
  children,
  label,
  disabled,
  ...fieldProps
}) => {
  return (
    <div
      className={[
        'field-wrapper',
        !!disabled ? 'field-wrapper--disabled' : '',
      ].join(' ')}
    >
      {!!label && <span className="field-wrapper__label">{label}</span>}
      <Field {...fieldProps}>
        {(control, meta) => (
          <MyField field={{ control, meta }}>{children}</MyField>
        )}
      </Field>
    </div>
  );
};

export default FieldWrapper;
