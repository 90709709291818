import * as React from 'react';
import AppRoutes from 'routes';
import useLogin from 'hooks/useLogin';

const App: React.FC = () => {
  useLogin();

  let vh = window.innerHeight * 0.01;

  React.useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [vh]);

  return <AppRoutes />;
};

export default App;
