import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import LoadingView from 'views/Loading';
import FieldWrapper from 'components/FieldWrapper';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { validateConsent } from 'helpers/validators';
import useAuthologic from 'hooks/useAuthologic';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import { finishAuthologic } from 'redux/authologic/thunk';
import { routes } from 'routes';
import 'views/Authologic/styles.scss';
import useWindowDimensions from 'hooks/useWindowDimension';

const classNamePrefix: 'authologic' = 'authologic';

const AuthologicView: React.FC = () => {
  const token = useAppSelector(
    (state) => state.Data.app?.applicationDetails.token
  );
  const appId = useAppSelector(
    (state) => state.Data.app?.applicationDetails.id
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const iframeHeight = `${height * 0.85}px`;

  const handleIframeMessage = React.useCallback(
    (e: any) => {
      if (e.origin === process.env.REACT_APP_APP_URL && e?.data?.processed) {
        dispatch(
          finishAuthologic({
            token: token ?? '',
          })
        );
        navigate(routes.authologicFlow);
      }
    },
    [navigate, token, dispatch]
  );

  React.useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [handleIframeMessage]);

  const {
    iframeUrl,
    consents,
    consentsDescription,
    onNextStep,
    isGenerated,
    isLoading,
    isWaited,
  } = useAuthologic();

  if ((!isGenerated && isLoading) || isWaited) {
    return (
      <LoadingView
        text="Przetwarzamy Twoje dane"
        textBottom="To może chwilę potrwać"
      />
    );
  }

  return (
    <div className={classNamePrefix}>
      {!!iframeUrl && (
        <iframe
          allow="camera *;fullscreen *"
          title="authologic"
          allowFullScreen
          src={iframeUrl}
          height={iframeHeight}
        />
      )}

      {consentsDescription && (
        <ReactMarkdown
          linkTarget="_blank"
          className={`${classNamePrefix}__consents-description`}
        >
          {consentsDescription}
        </ReactMarkdown>
      )}
      {!!consents?.length && (
        <FormWithScrollToError onFinish={onNextStep}>
          <div>
            {consents.map(({ id, name, text }) => (
              <FieldWrapper
                key={id}
                name={name}
                valuePropName="checked"
                initialValue={false}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validateConsent(_, value, true),
                  },
                ]}
              >
                <CheckBox isMarkdown content={text} isRequired={true} />
              </FieldWrapper>
            ))}
          </div>

          <Button loading={isLoading} styleType="primary">
            Potwierdź
          </Button>
        </FormWithScrollToError>
      )}
    </div>
  );
};

export default AuthologicView;
